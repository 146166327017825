import React from "react";
import { createRoot } from 'react-dom/client';
import "./css/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";



const container = document.getElementById('root');
const root = createRoot(container);

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

root.render(
	<Auth0Provider
		domain= {domain}
		clientId={clientId}
		authorizationParams={{
			redirect_uri: window.location.origin,
			audience: process.env.REACT_APP_AUTH0_AUDIENCE,
			scope: "api.stock.get",
		}}
	>
		<App />
	</Auth0Provider>
	);

reportWebVitals();
