import React from "react";
import '../../css/search.css'


export default function HeaderLogo(props){
    return(
        <div className="logo-container">
            <img className="logo-header" src="./zago.png" width="50" alt="groupe zago"/>           
        </div>
        )
}