import React from "react";
import Search from "./components/Search";
import Zago from "./images/logo-zago.png";
import "./css/App.css";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
	const { loginWithRedirect, isAuthenticated } = useAuth0();

	return (
		<div className="App">
			{!isAuthenticated && (
				<div className="login">
					<div className="pre-login-card">
						<div>
							<img src={Zago} width="100" alt="logo" />
							<h4 className="subtitle">INSTOCK V.2.0</h4>
						</div>
						<div>
							<p>
								<button
									className="auth0-button"
									onClick={loginWithRedirect}
								>
									<img
										src="./auth0_logo.png"
										width="50"
										alt="Auth0"
									/>
									<h4 className="text-button-connexion">
										CONNEXION
									</h4>
								</button>
							</p>
						</div>
						<h6>
							Vous n'êtes pas{" "}
							{isAuthenticated ? "Logged in" : "connecté"}
						</h6>
					</div>
				</div>
			)}
			{isAuthenticated && <Search />}
		</div>
	);
}

export default App;
